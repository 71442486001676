import { Outlet, Link, Form } from "react-router-dom";
import React from "react";
import './Footer.css';
const Foot = () => {
    return (
      <>
     <section id="footer">
  <div class="container">
  <div class="row text-center text-xs-center text-sm-left text-md-left">
    <div class="col-xs-12 col-sm-4 col-md-4">
      <h5>Quick links</h5>
      <ul class="list-unstyled quick-links">
        <li><a href="https://www.fiverr.com/sunlimetech/design-and-fix-your-bootstrap-4-issues"><i class="fa fa-angle-double-right"></i>Home</a></li>
        <li><a href="https://www.fiverr.com/sunlimetech/design-and-fix-your-bootstrap-4-issues"><i class="fa fa-angle-double-right"></i>About</a></li>
        <li><a href="https://www.fiverr.com/sunlimetech/design-and-fix-your-bootstrap-4-issues"><i class="fa fa-angle-double-right"></i>FAQ</a></li>
        <li><a href="https://www.fiverr.com/sunlimetech/design-and-fix-your-bootstrap-4-issues"><i class="fa fa-angle-double-right"></i>Get Started</a></li>
        <li><a href="https://www.fiverr.com/sunlimetech/design-and-fix-your-bootstrap-4-issues"><i class="fa fa-angle-double-right"></i>Videos</a></li>
      </ul>
    </div>
    <div class="col-xs-12 col-sm-4 col-md-4">
      <h5>Quick links</h5>
      <ul class="list-unstyled quick-links">
        <li><a href="https://www.fiverr.com/sunlimetech/design-and-fix-your-bootstrap-4-issues"><i class="fa fa-angle-double-right"></i>Home</a></li>
        <li><a href="https://www.fiverr.com/sunlimetech/design-and-fix-your-bootstrap-4-issues"><i class="fa fa-angle-double-right"></i>About</a></li>
        <li><a href="https://www.fiverr.com/sunlimetech/design-and-fix-your-bootstrap-4-issues"><i class="fa fa-angle-double-right"></i>FAQ</a></li>
        <li><a href="https://www.fiverr.com/sunlimetech/design-and-fix-your-bootstrap-4-issues"><i class="fa fa-angle-double-right"></i>Get Started</a></li>
        <li><a href="https://www.fiverr.com/sunlimetech/design-and-fix-your-bootstrap-4-issues"><i class="fa fa-angle-double-right"></i>Videos</a></li>
      </ul>
    </div>
    <div class="col-xs-12 col-sm-4 col-md-4">
      <h5>Quick links</h5>
      <ul class="list-unstyled quick-links">
        <li><a href="https://www.fiverr.com/sunlimetech/design-and-fix-your-bootstrap-4-issues"><i class="fa fa-angle-double-right"></i>Home</a></li>
        <li><a href="https://www.fiverr.com/sunlimetech/design-and-fix-your-bootstrap-4-issues"><i class="fa fa-angle-double-right"></i>About</a></li>
        <li><a href="https://www.fiverr.com/sunlimetech/design-and-fix-your-bootstrap-4-issues"><i class="fa fa-angle-double-right"></i>FAQ</a></li>
        <li><a href="https://www.fiverr.com/sunlimetech/design-and-fix-your-bootstrap-4-issues"><i class="fa fa-angle-double-right"></i>Get Started</a></li>
        <li><a href="https://wwwe.sunlimetech.com" title="Design and developed by"><i class="fa fa-angle-double-right"></i>Imprint</a></li>
      </ul>
    </div>
  </div>
  <div class="row">
    <div class="col-xs-12 col-sm-12 col-md-12 mt-2 mt-sm-5">
      <ul class="list-unstyled list-inline social text-center">
        <li class="list-inline-item"><a href="https://www.fiverr.com/sunlimetech/design-and-fix-your-bootstrap-4-issues"><i class="fa fa-facebook"></i></a></li>
        <li class="list-inline-item"><a href="https://www.fiverr.com/sunlimetech/design-and-fix-your-bootstrap-4-issues"><i class="fa fa-twitter"></i></a></li>
        <li class="list-inline-item"><a href="https://www.fiverr.com/sunlimetech/design-and-fix-your-bootstrap-4-issues"><i class="fa fa-instagram"></i></a></li>
        <li class="list-inline-item"><a href="https://www.fiverr.com/sunlimetech/design-and-fix-your-bootstrap-4-issues"><i class="fa fa-google-plus"></i></a></li>
        <li class="list-inline-item"><a href="https://www.fiverr.com/sunlimetech/design-and-fix-your-bootstrap-4-issues" target="_blank"><i class="fa fa-envelope"></i></a></li>
      </ul>
    </div>
    <hr/>
  </div>	
  <div class="row">
    <div class="col-xs-12 col-sm-12 col-md-12 mt-2 mt-sm-2 text-center text-white">
      <p><u><a href="https://www.nationaltransaction.com/">National Transaction Corporation</a></u> is a Registered MSP/ISO of Elavon, Inc. Georgia [a wholly owned subsidiary of U.S. Bancorp, Minneapolis, MN]</p>
      <p class="h6">&copy All right Reversed.<a class="text-green ml-2" href="https://www.fiverr.com/sunlimetech/design-and-fix-your-bootstrap-4-issues" target="_blank">Sunlimetech</a></p>
    </div>
    <hr/>
  </div>	
</div>
</section>
      
      </>
    )
  };
  
  export default Foot;