import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Nav from "./Navbar/Navbar";
import Home from "./Home/Home";
import Foot from "./Footer/Footer";
import Product from "./otherpages/Product";
import Second from "./otherpages/Second";
function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="Nav" element={<Nav />}/>
        <Route path="/" element={<Home />}/>
        <Route path="Foot" element={<Foot />}/>
        <Route path="Product" element={<Product />}/>
        <Route path="Second" element={<Second />}/>

      </Routes>
    </BrowserRouter>
  );
}

export default App;
