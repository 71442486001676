import { Outlet, Link, Form } from "react-router-dom";
import React from "react";
import './secondproduct.css';
import Nav from "../Navbar/Navbar";
import Foot from "../Footer/Footer";
const Second = () => {
    
    return (
      <>
      <Nav/>
      <div class="container">
    <div class="row">
        <div class="col-lg-3 col-md-6 col-sm-12">
            <div class="product-card">
                <img src="/imges/roampers.jpg" alt="Geometric Art Design Cushion"/>
                <h5>Geometric Art Design – Cushion</h5>
                <p>Canvas - Full Printed Cushion</p>
                <p><strong>₨ 1,000</strong></p>
                <button class="btn btn-primary">Add to cart</button>
            </div>
        </div>
        <div class="col-lg-3 col-md-6 col-sm-12">
            <div class="product-card">
                <img src="image2.jpg" alt="Happy Mothers Day Cushion"/>
                <h5>Happy Mothers Day Cushion</h5>
                <p>Canvas - Full Printed Cushion</p>
                <p><strong>₨ 1,000</strong></p>
                <button class="btn btn-primary">Add to cart</button>
            </div>
        </div>
        <div class="col-lg-3 col-md-6 col-sm-12">
            <div class="product-card">
                <img src="image3.jpg" alt="Husband Fur Cushion"/>
                <h5>Husband Fur Cushion</h5>
                <p>Cushions Covers</p>
                <p><strong>₨ 1,000</strong></p>
                <button class="btn btn-primary">Add to cart</button>
            </div>
        </div>
        <div class="col-lg-3 col-md-6 col-sm-12">
            <div class="product-card">
                <img src="image4.jpg" alt="I Love North America Cushion"/>
                <h5>I Love North America Cushion</h5>
                <p>Canvas - Full Printed Cushion</p>
                <p><strong>₨ 1,000</strong></p>
                <button class="btn btn-primary">Add to cart</button>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-lg-3 col-md-6 col-sm-12">
            <div class="product-card">
                <img src="image1.jpg" alt="Geometric Art Design Cushion"/>
                <h5>Geometric Art Design – Cushion</h5>
                <p>Canvas - Full Printed Cushion</p>
                <p><strong>₨ 1,000</strong></p>
                <button class="btn btn-primary">Add to cart</button>
            </div>
        </div>
        <div class="col-lg-3 col-md-6 col-sm-12">
            <div class="product-card">
                <img src="image2.jpg" alt="Happy Mothers Day Cushion"/>
                <h5>Happy Mothers Day Cushion</h5>
                <p>Canvas - Full Printed Cushion</p>
                <p><strong>₨ 1,000</strong></p>
                <button class="btn btn-primary">Add to cart</button>
            </div>
        </div>
        <div class="col-lg-3 col-md-6 col-sm-12">
            <div class="product-card">
                <img src="image3.jpg" alt="Husband Fur Cushion"/>
                <h5>Husband Fur Cushion</h5>
                <p>Cushions Covers</p>
                <p><strong>₨ 1,000</strong></p>
                <button class="btn btn-primary">Add to cart</button>
            </div>
        </div>
        <div class="col-lg-3 col-md-6 col-sm-12">
            <div class="product-card">
                <img src="image4.jpg" alt="I Love North America Cushion"/>
                <h5>I Love North America Cushion</h5>
                <p>Canvas - Full Printed Cushion</p>
                <p><strong>₨ 1,000</strong></p>
                <button class="btn btn-primary">Add to cart</button>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-lg-3 col-md-6 col-sm-12">
            <div class="product-card">
                <img src="image1.jpg" alt="Geometric Art Design Cushion"/>
                <h5>Geometric Art Design – Cushion</h5>
                <p>Canvas - Full Printed Cushion</p>
                <p><strong>₨ 1,000</strong></p>
                <button class="btn btn-primary">Add to cart</button>
            </div>
        </div>
        <div class="col-lg-3 col-md-6 col-sm-12">
            <div class="product-card">
                <img src="image2.jpg" alt="Happy Mothers Day Cushion"/>
                <h5>Happy Mothers Day Cushion</h5>
                <p>Canvas - Full Printed Cushion</p>
                <p><strong>₨ 1,000</strong></p>
                <button class="btn btn-primary">Add to cart</button>
            </div>
        </div>
        <div class="col-lg-3 col-md-6 col-sm-12">
            <div class="product-card">
                <img src="image3.jpg" alt="Husband Fur Cushion"/>
                <h5>Husband Fur Cushion</h5>
                <p>Cushions Covers</p>
                <p><strong>₨ 1,000</strong></p>
                <button class="btn btn-primary">Add to cart</button>
            </div>
        </div>
        <div class="col-lg-3 col-md-6 col-sm-12">
            <div class="product-card">
                <img src="image4.jpg" alt="I Love North America Cushion"/>
                <h5>I Love North America Cushion</h5>
                <p>Canvas - Full Printed Cushion</p>
                <p><strong>₨ 1,000</strong></p>
                <button class="btn btn-primary">Add to cart</button>
            </div>
        </div>
    </div>

    <div class="pagination-custom">
        <nav aria-label="Page navigation">
            <ul class="pagination">
                <li class="page-item">
                    <a class="page-link" href="#" aria-label="Previous">
                        <span aria-hidden="true">&laquo;</span>
                    </a>
                </li>
                <li class="page-item"><a class="page-link" href="#" data-page="1">1</a></li>
                <li class="page-item"><a class="page-link" href="#" data-page="2">2</a></li>
                <li class="page-item active"><a class="page-link" href="#" data-page="3">3</a></li>
                <li class="page-item"><a class="page-link" href="#" data-page="4">4</a></li>
                <li class="page-item"><a class="page-link" href="#" data-page="5">5</a></li>
                <li class="page-item"><a class="page-link" href="#" data-page="6">6</a></li>
                <li class="page-item">
                    <a class="page-link" href="#" aria-label="Next">
                        <span aria-hidden="true">&raquo;</span>
                    </a>
                </li>
            </ul>
        </nav>
    </div>
</div>
 <Foot/>
      </>
    )
  };
  
  export default Second;