import { Outlet, Link, Form } from "react-router-dom";
import React from "react";
import './product.css';
import Nav from "../Navbar/Navbar";
import Foot from "../Footer/Footer";
const Product = () => {
    
    return (
      <>
      <Nav/>
        <div class="container">
        {/* <!-- Breadcrumb --> */}
      

        {/* <!-- Page Heading --> */}
        <h1 class="mt-4">Tshirts</h1>

        {/* <!-- Results Count --> */}
        <p class="results-count">Showing all 10 results</p>

        {/* <!-- Sorting Dropdown --> */}
        <div class="sorting-dropdown">
            <select class="form-select" aria-label="Default sorting">
                <option selected>Default sorting</option>
                <option value="1">Sort by popularity</option>
                <option value="2">Sort by average rating</option>
                <option value="3">Sort by latest</option>
                <option value="4">Sort by price: low to high</option>
                <option value="5">Sort by price: high to low</option>
            </select>
        </div>
    </div>





    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-md-6 col-sm-12 product-card">
                <div class="card">
                    <img src="/imges/roampers.jpg" class="card-img-top" alt="Bangtan Boys T shirt"/>
                    <div class="card-body">
                        <p class="card-text">Tshirts</p>
                        <h5 class="card-title">Bangtan Boys T shirt</h5>
                        <p class="card-price">Rs 1,200</p>
                        <a href="#" class="btn btn-primary card-button">Select options</a>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-12 product-card">
                <div class="card">
                    <img src="../imges/money-bank.jpg" class="card-img-top" alt="CR 7 Tshirt"/>
                    <div class="card-body">
                        <p class="card-text">Shirt</p>
                        <h5 class="card-title">CR 7 Tshirt</h5>
                        <p class="card-price">Rs 1,200</p>
                        <a href="#" class="btn btn-primary card-button">Select options</a>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-12 product-card">
                <div class="card">
                    <img src="https://via.placeholder.com/150" class="card-img-top" alt="Dynamite Shirt"/>
                    <div class="card-body">
                        <p class="card-text">Tshirts</p>
                        <h5 class="card-title">Dynamite Shirt</h5>
                        <p class="card-price">Rs 1,200</p>
                        <a href="#" class="btn btn-primary card-button">Select options</a>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-12 product-card">
                <div class="card">
                    <img src="https://via.placeholder.com/150" class="card-img-top" alt="Game of Thrones Stark Winter is Coming Tshirt"/>
                    <div class="card-body">
                        <p class="card-text">Tshirts</p>
                        <h5 class="card-title">Game of Thrones Stark Winter is Coming Tshirt</h5>
                        <p class="card-price">Rs 1,200</p>
                        <a href="#" class="btn btn-primary card-button">Select options</a>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-3 col-md-6 col-sm-12 product-card">
                <div class="card">
                    <img src="https://via.placeholder.com/150" class="card-img-top" alt="Bangtan Boys T shirt"/>
                    <div class="card-body">
                        <p class="card-text">Tshirts</p>
                        <h5 class="card-title">Bangtan Boys T shirt</h5>
                        <p class="card-price">Rs 1,200</p>
                        <a href="#" class="btn btn-primary card-button">Select options</a>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-12 product-card">
                <div class="card">
                    <img src="https://via.placeholder.com/150" class="card-img-top" alt="CR 7 Tshirt"/>
                    <div class="card-body">
                        <p class="card-text">Shirt</p>
                        <h5 class="card-title">CR 7 Tshirt</h5>
                        <p class="card-price">Rs 1,200</p>
                        <a href="#" class="btn btn-primary card-button">Select options</a>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-12 product-card">
                <div class="card">
                    <img src="https://via.placeholder.com/150" class="card-img-top" alt="Dynamite Shirt"/>
                    <div class="card-body">
                        <p class="card-text">Tshirts</p>
                        <h5 class="card-title">Dynamite Shirt</h5>
                        <p class="card-price">Rs 1,200</p>
                        <a href="#" class="btn btn-primary card-button">Select options</a>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-12 product-card">
                <div class="card">
                    <img src="https://via.placeholder.com/150" class="card-img-top" alt="Game of Thrones Stark Winter is Coming Tshirt"/>
                    <div class="card-body">
                        <p class="card-text">Tshirts</p>
                        <h5 class="card-title">Game of Thrones Stark Winter is Coming Tshirt</h5>
                        <p class="card-price">Rs 1,200</p>
                        <a href="#" class="btn btn-primary card-button">Select options</a>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-3 col-md-6 col-sm-12 product-card">
                <div class="card">
                    <img src="https://via.placeholder.com/150" class="card-img-top" alt="Bangtan Boys T shirt"/>
                    <div class="card-body">
                        <p class="card-text">Tshirts</p>
                        <h5 class="card-title">Bangtan Boys T shirt</h5>
                        <p class="card-price">Rs 1,200</p>
                        <a href="#" class="btn btn-primary card-button">Select options</a>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-12 product-card">
                <div class="card">
                    <img src="https://via.placeholder.com/150" class="card-img-top" alt="CR 7 Tshirt"/>
                    <div class="card-body">
                        <p class="card-text">Shirt</p>
                        <h5 class="card-title">CR 7 Tshirt</h5>
                        <p class="card-price">Rs 1,200</p>
                        <a href="#" class="btn btn-primary card-button">Select options</a>
                    </div>
                </div>
            </div>
            </div>
        </div>
 <Foot/>
      </>
    )
  };
  
  export default Product;