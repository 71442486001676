import { Outlet, Link, Form } from "react-router-dom";
import React from "react";
import './Navbar.css';
const Nav = () => {
    return (
      <>
      
       <nav class="navbar1">
            <div class="logo">
                <img src="../imges/logo.png" alt="Logo"
                    style={{ height: '90px', width: '90px' }} />
            </div>
        <div class="hamburger" id="hamburger">
            <span class="bar"></span>
            <span class="bar"></span>
            <span class="bar"></span>
        </div>
        <ul class="nav-links1" id="nav-links1">
            <li class="nav-item1">
                <a href="#">Home & Office</a>
                <ul class="dropdown1">
                    <li>heelo</li>
                </ul>
            </li>
            <li class="nav-item1">
                <a href="#">Men</a>
                <ul class="dropdown1">
                    {/* <!-- Add dropdown items here --> */}
                </ul>
            </li>
            <li class="nav-item1">
                <a href="#">Women</a>
                <ul class="dropdown1">
                    {/* <!-- Add dropdown items here --> */}
                </ul>
            </li>
            <li class="nav-item1">
                <a href="#">Bags</a>
                <ul class="dropdown1">
                    {/* <!-- Add dropdown items here --> */}
                </ul>
            </li>
            <li class="nav-item1">
                <a href="#">Kids & Baby</a>
                <ul class="dropdown1">
                    {/* <!-- Add dropdown items here --> */}
                </ul>
            </li>
            <li class="nav-item1">
                <a href="#">Stationary</a>
                <ul class="dropdown1">
                    {/* <!-- Add dropdown items here --> */}
                </ul>
            </li>
            <li class="nav-item1">
                <a href="#">Accessories</a>
                <ul class="dropdown1">
                    {/* <!-- Add dropdown items here --> */}
                </ul>
            </li>
            <li class="nav-item1">
                <a href="#">Bundles</a>
                <ul class="dropdown1">
                    {/* <!-- Add dropdown items here --> */}
                </ul>
            </li>
        </ul>
        <div className="search-bar">
      <input
        type="text"
        placeholder="Search..."
      />
      <button className="d-none d-lg-block d-md-block"
       
        style={{ 
          border: '2px solid black', 
          padding: '5px', 
          backgroundColor: 'white' 
        }}
      >
        <img
          src="https://icons.veryicon.com/png/o/miscellaneous/prototyping-tool/search-bar-01.png"
          alt="Search"
          style={{ height: '20px', width: '20px' }}
        />
      </button>
    </div>
    </nav>
      </>
    )
  };
  
  export default Nav;