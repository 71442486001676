import React from "react";
import './Home.css';
import Nav from "../Navbar/Navbar";
import Foot from "../Footer/Footer";
import { Link } from "react-router-dom";
const Home=()=>{
   
 return(
    <>
    <Nav/>
  <div>
    <div id="carouselExampleControls" class="carousel slide" data-bs-ride="carousel">
        <div class="carousel-inner">
          <div class="carousel-item active">
            <img src="../imges/WhatsApp Image 2024-07-03 at 9.57.36 PM.jpeg" class="c-img d-block w-100" alt="..."/>
          </div>
          <div class="carousel-item">
            <img src="../imges/WhatsApp Image 2024-07-03 at 9.57.37 PM (1).jpeg" class="c-img d-block w-100" alt="..."/>
          </div>
          <div class="carousel-item">
            <img src="../imges/WhatsApp Image 2024-07-03 at 9.57.37 PM.jpeg" class="c-img d-block w-100" alt="..."/>
          </div>
        </div>
        <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Previous</span>
        </button>
        <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Next</span>
        </button>
      </div>
</div> <br/> 


 
<div class="container-fluid">
    <div class="row suport">
        <div class="col-lg-2 col-md-6 col-sm-12">
            <img src="../imges/trust-80x80.png" alt=""/>
            <p class="suport-txt">Trusted Brand</p>
        </div>
        <div class="col-lg-2 col-md-6 col-sm-12">
            <img src="../imges/support-80x80.png" alt=""/>
            <p class="suport-txt">24/7 Customer Support</p>
        </div>
        <div class="col-lg-2 col-md-6 col-sm-12">
            <img src="../imges/express-delivery.png" alt=""/>
            <p class="suport-txt">Express Delivery</p>
        </div>
        <div class="col-lg-2 col-md-6 col-sm-12">
            <img src="../imges/asia-80x80.png" alt=""/>
            <p class="suport-txt">Delivery All Over Pakistan</p>
        </div>
        <div class="col-lg-2 col-md-6 col-sm-12">
            <img src="../imges/transfer-80x80.png" alt=""/>
            <p class="suport-txt">Return Policy</p>
        </div>
    </div>

   

     {/* <!-- --------------ISLAMIC BANNER--------------- --> */}

             <div class="row suport1">
                <div class="col-lg-12 col-sm-12 col-md-12">
                    <img class="islamic-img" src="../imges/islamic-gifts-customizegiftspk-1536x226.jpg" alt=""/>
                </div>
            </div>
          

             {/* <!-- --------------FASHION BANNER--------------- --> */}
        
            <div class="row suport2">
                <div class="col-lg-12 col-md-12 col-sm-12">
                  <div>
                    <img class="islamic-img" src="../imges/fashion.jpg" alt=""/>
                  </div>
                </div>
            </div> <br/>

             {/* <!-- --------------FASHION items--------------- --> */}

             <div class="row ">
              <div class="col-lg-3 col-md-6 col-sm-12">
                <a href=""><img src="../imges/tees.jpg" alt=""/></a>
              </div>
              <div class="col-lg-3 col-md-6 col-sm-12">
                <a href=""><img src="../imges/sewatshirts.jpg" alt=""/></a>
              </div>
              <div class="col-lg-3 col-md-6 col-sm-12">
                <a href=""><img src="../imges/bomber-jackets.jpg" alt=""/></a>
              </div>
              <div class="col-lg-3 col-md-6 col-sm-12">
                <a href=""><img src="../imges/caps.jpg" alt=""/></a>
              </div>
             </div> <br/>
             <div class="row fashion">
              <div class="col-lg-6 col-md-6 col-sm-12 d-flex">
                <a href=""><img src="../imges/aprons.jpg" alt=""/></a> &ensp;   &ensp;  &ensp;  &ensp;  &ensp;
                <a href=""><img src="../imges/graphics-posket-tess.jpg" alt=""/></a>
              </div>
             </div><br/>

                {/* <!-- --------------HOME AND OFFICE--------------- --> */}
        
            <div class="row suport2">
              <div class="col-lg-12 col-md-12 col-sm-12">
                <div>
                  <img class="islamic-img" src="../imges/Banner-03.jpg" alt=""/>
                </div>
              </div>
          </div> <br/>
            {/* <!-- --------------HOME AND OFFICE ITEMS--------------- --> */}

          <div class="row">
            <div class="col-lg-1 col-md-12 col-sm-12"></div>
            <div class="col-lg-2 col-md-4 col-sm-6">
            <a href=""><img class="h-img" src="../imges/cushions.jpg" alt=""/></a>  <br/> <br/>
            <a href=""><img class="h-img" src="../imges/docking-stations.jpg" alt=""/></a>  <br/> <br/>
            <a href=""><img class="h-img" src="../imges/tile-frame.jpg" alt=""/></a> 
            </div>
            <div class="col-lg-2 col-md-4 col-sm-6">
              <a href=""><img class="h-img" src="../imges/mugs.jpg" alt=""/></a>  <br/> <br/>
              <a href=""><img class="h-img" src="../imges/magical-cushions.jpg" alt=""/></a>  <br/> <br/>
              <a href=""><img class="h-img" src="../imges/mens-wallet.jpg" alt=""/></a> 
             </div>
            <div class="col-lg-2 col-md-4 col-sm-6"> 
              
            <a href=""><img class="h-img" src="../imges/tea-coasters.jpg" alt=""/></a>  <br/> <br/>
            <a href=""><img class="h-img" src="../imges/table-frame-name.jpg" alt=""/></a>  <br/> <br/>
            <a href=""><img class="h-img" src="../imges/travelling-cushion.jpg" alt=""/></a> 
            </div>
            <div class="col-lg-2 col-md-4 col-sm-6"> 
              
            <a href=""><img class="h-img" src="../imges/wooden-frames.jpg" alt=""/></a>  <br/> <br/>
            <a href=""><img class="h-img" src="../imges/wall-clocks.jpg" alt=""/></a>  <br/> <br/>
            <a href=""><img class="h-img" src="../imges/wall-frames-1.jpg" alt=""/></a> 
            </div>
            <div class="col-lg-2 col-md-4 col-sm-6"> 
              
            <a href=""><img class="h-img" src="../imges/magical-mugs.jpg" alt=""/></a>  <br/> <br/>
            <a href=""><img class="h-img" src="../imges/female-wallets.jpg" alt=""/></a> 
            </div>
            <div class="col-lg-1 col-md-12 col-sm-12"></div>
          </div> <br/>


             {/* <!-- --------------FASHION JWELRY--------------- --> */}
        
             <div class="row suport2">
              <div class="col-lg-12 col-md-12 col-sm-12">
                <div>
                  <img class="islamic-img" src="../imges/Banner-14.jpg" alt=""/>
                </div>
              </div>
          </div> <br/>
           

          <div class="row">
            <div class="col-lg-1 col-md-12 col-sm-12"></div>
            <div class="col-lg-2 col-md-4 col-sm-6">
            <a href=""><img class="h-img" src="../imges/name-pendant.jpg" alt=""/></a>  <br/> <br/>
            <a href=""><img class="h-img" src="../imges/infinity-braclet.jpg" alt=""/></a>  <br/> <br/>
            <a href=""><img class="h-img" src="../imges/cufflinks.jpg" alt=""/></a> 
            </div>
            <div class="col-lg-2 col-md-4 col-sm-6">
              <a href=""><img class="h-img" src="../imges/name-bar-pendant.jpg" alt=""/></a>  <br/> <br/>
              <a href=""><img class="h-img" src="../imges/couple-rings.jpg" alt=""/></a>  <br/> <br/>
              <a href=""><img class="h-img" src="../imges/earrings.jpg" alt=""/></a> 
             </div>
            <div class="col-lg-2 col-md-4 col-sm-6"> 
              
            <a href=""><img class="h-img" src="../imges/name-pendant-flat.jpg" alt=""/></a>  <br/> <br/>
            <a href=""><img class="h-img" src="../imges/black-punk-rings.jpg" alt=""/></a>  <br/> <br/>
            <a href=""><img class="h-img" src="../imges/rope-bracelet.jpg" alt=""/></a> 
            </div>
            <div class="col-lg-2 col-md-4 col-sm-6"> 
              
            <a href=""><img class="h-img" src="../imges/name-pendant-couple.jpg" alt=""/></a>  <br/> <br/>
            <a href=""><img class="h-img" src="../imges/blac-beast-bracelet.jpg" alt=""/></a>  <br/> <br/>
            <a href=""><img class="h-img" src="../imges/tie-pin.jpg" alt=""/></a> 
            </div>
            <div class="col-lg-2 col-md-4 col-sm-6"> 
              
            <a href=""><img class="h-img" src="../imges/dual-heart-pendant.jpg" alt=""/></a>  <br/> <br/>
            <a href=""><img class="h-img" src="../imges/name-rings.jpg" alt=""/></a> <br/> <br/>
            <a href=""><img class="h-img" src="../imges/coat-pins.jpg" alt=""/></a> 
            </div>
            <div class="col-lg-1 col-md-12 col-sm-12"></div>
          </div> <br/>

{/* 
            <!-- --------------LIGHTNING LAMPS--------------- --> */}
        
            <div class="row suport2">
              <div class="col-lg-12 col-md-12 col-sm-12">
                <div>
                  <img class="islamic-img" src="../imges/Banner-12.jpg" alt=""/>
                </div>
              </div>
          </div> <br/>
        {/* <!-- --------------LIGHTNING LAMPS items--------------- --> */}
          <div class="row">
            <div class="col-lg-1 col-md-12 col-sm-12"></div>
            <div class="col-lg-2 col-md-4 col-sm-6">
            <a href=""><img class="h-img" src="../imges/01.jpg" alt=""/></a> <br/> <br/>
            <a href=""><img class="h-img" src="../imges/1.jpg" alt=""/></a>
            </div>
            <div class="col-lg-2 col-md-4 col-sm-6">
            <a href=""><img class="h-img" src="../imges/02.jpg" alt=""/></a>
            </div>
            <div class="col-lg-2 col-md-4 col-sm-6">
            <a href=""><img class="h-img" src="../imges/03.jpg" alt=""/></a>
            </div>
            <div class="col-lg-2 col-md-4 col-sm-6">
            <a href=""><img class="h-img" src="../imges/04.jpg" alt=""/></a>
            </div>
            <div class="col-lg-2 col-md-4 col-sm-6">
            <a href=""><img class="h-img" src="../imges/05.jpg" alt=""/></a>
            </div>
          </div>   <br/>


             {/* <!-- --------------ACCESSIRIES--------------- --> */}
        
             <div class="row suport2">
              <div class="col-lg-12 col-md-12 col-sm-12">
                <div>
                  <img class="islamic-img" src="../imges/Banner-10.jpg" alt=""/>
                </div>
              </div>
          </div> <br/>
            {/* <!-- --------------ACCESSIRIES ITEMS--------------- --> */}

          <div class="row">
            <div class="col-lg-1 col-md-12 col-sm-12"></div>
            <div class="col-lg-2 col-md-4 col-sm-6">
            <a href=""><img class="h-img" src="../imges/mobile-cover.jpg" alt=""/></a>  <br/> <br/>
            <a href=""><img class="h-img" src="../imges/calendar-keychain.jpg" alt=""/></a>  <br/> <br/>
            <a href=""><img class="h-img" src="../imges/wood-universal-calendar.jpg" alt=""/></a> 
            </div>
            <div class="col-lg-2 col-md-4 col-sm-6">
              <a href=""><img class="h-img" src="../imges/zippo-lighters.jpg" alt=""/></a>  <br/> <br/>
              <a href=""><img class="h-img" src="../imges/broken-heart-keychain.jpg" alt=""/></a>  <br/> <br/>
              <a href=""><img class="h-img" src="../imges/wood-pen-boxx.jpg" alt=""/></a> 
             </div>
            <div class="col-lg-2 col-md-4 col-sm-6"> 
              
            <a href=""><img class="h-img" src="../imges/wood-usb.jpg" alt=""/></a>  <br/> <br/>
            <a href=""><img class="h-img" src="../imges/photo-keychain.jpg" alt=""/></a>  <br/> <br/>
            <a href=""><img class="h-img" src="../imges/business-card-holder.jpg" alt=""/></a> 
            </div>
            <div class="col-lg-2 col-md-4 col-sm-6"> 
              
            <a href=""><img class="h-img" src="../imges/badges.jpg" alt=""/></a>  <br/> <br/>
            <a href=""><img class="h-img" src="../imges/wood-keychains.jpg" alt=""/></a>  <br/> <br/>
            <a href=""><img class="h-img" src="../imges/pen-card-holder.jpg" alt=""/></a> 
            </div>
            <div class="col-lg-2 col-md-4 col-sm-6"> 
              
            <a href=""><img class="h-img" src="../imges/pop-sockets.jpg" alt=""/></a>  <br/> <br/>
            <a href=""><img class="h-img" src="../imges/wallet-card-insert.jpg" alt=""/></a> <br/> <br/>
            <a href=""><img class="h-img" src="../imges/wrist-watches.jpg" alt=""/></a> 
            </div>
            <div class="col-lg-1 col-md-12 col-sm-12"></div>
          </div> <br/>


           {/* <!-- --------------STATIONARY--------------- --> */}
        
           <div class="row suport2">
            <div class="col-lg-12 col-md-12 col-sm-12">
              <div>
                <img class="islamic-img" src="../imges/Banner-07.jpg" alt=""/>
              </div>
            </div>
        </div> <br/>
          {/* <!-- --------------STATIONARY ITEMS--------------- --> */}

        <div class="row">
          <div class="col-lg-1 col-md-12 col-sm-12"></div>
          <div class="col-lg-2 col-md-4 col-sm-6">
          <a href=""><img class="h-img" src="../imges/backpack-adult.jpg" alt=""/></a>  <br/> <br/>
          <a href=""><img class="h-img" src="../imges/draw-string-bags.jpg" alt=""/></a> 
          </div>
          <div class="col-lg-2 col-md-4 col-sm-6">
            <a href=""><img class="h-img" src="../imges/exam-board.jpg" alt=""/></a>  <br/> <br/>
            <a href=""><img class="h-img" src="../imges/pouches.jpg" alt=""/></a> 
           </div>
          <div class="col-lg-2 col-md-4 col-sm-6"> 
            
          <a href=""><img class="h-img" src="../imges/waterbottles.jpg" alt=""/></a>  <br/> <br/>
          <a href=""><img class="h-img" src="../imges/notebooks.jpg" alt=""/></a> 
          </div>
          <div class="col-lg-2 col-md-4 col-sm-6"> 
            
          <a href=""><img class="h-img" src="../imges/toto-bags.jpg" alt=""/></a>  <br/> <br/>
          <a href=""><img class="h-img" src="../imges/pen.jpg" alt="/"/></a> 
          </div>
          <div class="col-lg-2 col-md-4 col-sm-6"> 
            
          <a href=""><img class="h-img" src="../imges/laptop-sleeves.jpg" alt=""/></a>  <br/> <br/>
          <a href=""><img class="h-img" src="../imges/mouse-pad.jpg" alt=""/></a>
          </div>
          <div class="col-lg-1 col-md-12 col-sm-12"></div>
        </div> <br/>

           {/* <!-- --------------STATIONARY--------------- --> */}
        
           <div class="row suport2">
            <div class="col-lg-12 col-md-12 col-sm-12">
              <div>
                <img class="islamic-img" src="../imges/Banner-09.jpg" alt=""/>
              </div>
            </div>
        </div> <br/>
          {/* <!-- --------------STATIONARY ITEMS--------------- --> */}

        <div class="row">
          <div class="col-lg-1 col-md-12 col-sm-12"></div>
          <div class="col-lg-2 col-md-4 col-sm-6">
          <a href=""><img class="h-img" src="../imges/roampers.jpg" alt=""/></a>  
          </div>
          <div class="col-lg-2 col-md-4 col-sm-6">
            <a href=""><img class="h-img" src="../imges/money-bank.jpg" alt=""/></a>  
           </div>
          <div class="col-lg-2 col-md-4 col-sm-6"> 
            
          <a href=""><img class="h-img" src="../imges/tees.jpg" alt=""/></a> 
          </div>
          <div class="col-lg-2 col-md-4 col-sm-6"> 
            
          <a href=""><img class="h-img" src="../imges/kids-bags.jpg" alt=""/></a> 
          </div>
          <div class="col-lg-2 col-md-4 col-sm-6"> 
            
          <a href=""><img class="h-img" src="../imges/pencil-pouches.jpg" alt=""/></a> 
          </div>
          <div class="col-lg-1 col-md-12 col-sm-12"></div>
        </div> <br/> <br/> <br/> 


        {/* <!-- ----------------------grid-------------> */}

        <div class="row">
          <div class="col-lg-1 col-md-12 col-sm-12"></div>
          <div class="col-lg-5 col-md-6 col-sm-12">
          <a href=""><img class="grid-img" src="../imges/grid-05.jpg" alt=""/></a>  
          </div>
          <div class="col-lg-5 col-md-6 col-sm-12">
            <a href=""><img class="grid-img" src="../imges/grid-03.jpg" alt=""/></a>  
           </div>
        
         
          <div class="col-lg-1 col-md-12 col-sm-12"></div>
        </div> <br/> <hr/>


        <div class="row">
          <div class="col-lg-12 col-md-12 col-sm-12">
            <div class="d-flex">
              <p class="d-txt">Pay With Ease</p>
              <img class="pay-img" src="../imges/easypesa.jpg"/>
              <img class="pay-img" src="../imges/jazzcash.jpg"/>
              <img class="pay-img" src="../imges/lion.jpg"/>
              <img class="pay-img" src="../imges/alhabib.jpg"/>
              <img class="pay-img" src="../imges/hbl.jpg"/>
              <img class="pay-img" src="../imges/wu.jpg"/>
            </div>
          </div>
         </div>



         {/* <!------------------------footer img-----------------------> */}

         <div class="row">
          <div class="col-lg-12 col-md-12 col-sm-12">
            <img class="f-img" src="../imges/banner-international-delivery.jpg"/>
          </div>
         </div>


         <div class="row">
          <div class="col-lg-12 col-md-12 col-sm-12">
            <div class="d-flex">
              <p class="d-txt">Delivery <br/> Partners</p>
              <img class="d-img" src="../imges/a.jpg"/>
              <img class="d-img" src="../imges/b.jpg"/>
              <img class="d-img" src="../imges/c.jpg"/>
              <img class="d-img" src="../imges/d.jpg"/>
              <img class="d-img" src="../imges/e.jpg"/>
              <img class="d-img" src="../imges/f.jpg"/>
            </div>
          </div>
         </div>


         <div class="row">
          <div class="col-lg-12 col-md-12 col-sm-12">
            <img class="f-img" src="../imges/footer-top-banner.jpg"/>
          </div>
         </div>

</div>
    
    <Foot/>
   </>
 )




}
export default Home;